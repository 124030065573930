






















import Vue from 'vue'

export default Vue.extend({
  name: 'CollapseList',
  data () {
    return {
      opened: false
    }
  },
  props: {
    selected: {
      type: Object
    },
    list: {
      type: Array
    },
    name: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'selected',
    event: 'select'
  },
  methods: {
    select (elem) {
      this.$emit('select', elem)
    }
  },
  computed: {
    platform () {
      return this.name[0].toUpperCase() + this.name.slice(1, this.name.length) + ' platform'
    }
  },
  created () {
    if (this.list.find(v => v === this.selected)) {
      this.opened = true
    }
  }
})
