
































































































import Vue from 'vue'

import CollapseList from '@/components/ADSManager/NewCampaign/CollapseList.vue'
import CollapseLists from '@/components/ADSManager/NewCampaign/CollapseLists.vue'
import BaseCirclePreloader from '@/components/BaseCirclePreloader.vue'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'LivePreview',
  components: {
    CollapseList,
    CollapseLists,
    BaseCirclePreloader
  },
  data () {
    return {
      loading: false,
      error: false,
      body: null,
      variation: 0,
      selected: null,
      selectedAllAds: null,
      count: 0
    }
  },
  watch: {
    selected () {
      this.error = false
      this.getAdPreview()
    },
    selectedAllAds (selected) {
      this.error = false
      this.loadLivePreview(selected)
    }
  },
  props: {
    singleAd: {
      required: true
    },
    campaignId: {
      default: null
    },
    idAd: {
      required: false
    },
    lists: {
      required: false,
      type: Object
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', [
      'adDesignServerData',
      'adDesignData'
    ]),
    ...mapGetters('adsManager/adDesign', [
      'getList',
      'getPreview',
      'getLoadingList',
      'getLoadingPreview'
    ]),
    ...mapGetters('allAds', { getAdPreviewStore: 'getAdPreview' }),
    ...mapState('adsManager', [
      'campaign'
    ])
  },
  methods: {
    ...mapActions('allAds', [
      'load_ad_preview'
    ]),
    ...mapActions('adsManager/adDesign', [
      'get_live_ad_previews'
    ]),
    ...mapMutations('allAds', [
      'setAdPreview'
    ]),
    loadLivePreview (selected) {
      this.loading = true
      const rqLoadAdPreview = {
        requestBody: {
          ad_id: this.idAd,
          ad_format: selected?.value
        },
        campaignId: this.campaignId
      }
      this.load_ad_preview(rqLoadAdPreview)
        .then(() => {
          this.loading = false
          this.error = false
        })
        .catch((error) => {
          this.loading = false
          this.error = error.response.data.errors[0].detail[0]
        })
    },
    getAdPreview () {
      this.loading = true
      const ad_format = this.selected.value
      let data
      if (this.adDesignData.creative_format === 'normal') {
        data = {
          ad_format: ad_format,
          page_id: this.adDesignData.page_id,
          instagram_id: this.adDesignData.instagram_id,
          creative_format: this.adDesignData.creative_format
        }
      } else {
        data = {
          ad_format: ad_format,
          page_id: this.adDesignData.page_id,
          instagram_id: this.adDesignData.instagram_id,
          multi_share_end_card: this.adDesignData.multi_share_end_card,
          multi_share_optimized: this.adDesignData.multi_share_optimized,
          creative_format: this.adDesignData.creative_format
        }
      }

      this.get_live_ad_previews({
        campaignId: this.$route.params.campaign_id,
        data: data,
        variation: this.variation,
        objective: this.campaign.objective
      })
        .then((response) => {
          this.body = response.data.data[0].body
          this.error = false
          this.loading = false
        })
        .catch((error) => {
          console.log('error')
          console.log(error.response.data.errors[0].detail[0])
          this.error = error.response.data.errors[0].detail[0]
          this.loading = false
        })
    }
  },
  created () {
    this.body = null
    this.setAdPreview(null)
    if (this.singleAd) {
      const keys = Object.keys(this.lists)
      this.selectedAllAds = this.lists[keys[0]][0]
    } else {
      const keys = Object.keys(this.getList)
      this.selected = this.getList[keys[0]][0]
    }
  }
})
