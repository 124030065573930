










import Vue from 'vue'
import CollapseList from '@/components/ADSManager/NewCampaign/CollapseList.vue'

export default Vue.extend({
  name: 'CollapseLists',
  components: {
    CollapseList
  },
  props: {
    lists: {
      type: Object
    },
    selected: {
      type: Object
    }
  },
  model: {
    prop: 'selected',
    event: 'select'
  },
  computed: {
    model: {
      get () {
        return this.selected
      },
      set (elem) {
        this.$emit('select', elem)
      }
    }
  }
})
